import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import GUI from 'lil-gui';

const html = document.querySelector('html');
html.style.backgroundColor = '#1e1a20';

/**
 * Debug
 */
const gui = new GUI();

const parameters = {
  Background: '#1e1a20',
  Shape: '#ffeded',
  Particles: '#ffeded'
};

gui.addColor(parameters, 'Background').onChange(() => {
  html.style.backgroundColor = parameters.Background;
});
gui.addColor(parameters, 'Shape').onChange(() => {
  material.color.set(parameters.Shape);
});
gui.addColor(parameters, 'Particles').onChange(() => {
  particlesMaterial.color.set(parameters.Particles);
});
gui.close()

/**
 * Config
 */
const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();

/**
 * Objects
 */

// Material
const material = new THREE.MeshStandardMaterial({
  color: parameters.materialColor,
  metalness: 0.1,
  roughness: 0.1
});

// Meshs
const meshRotationX = 0.9;
const meshRotationY = 0.6;

const objectsDistance = 4;
const mesh1 = new THREE.Mesh(new THREE.TorusKnotGeometry(0.4, 0.15, 112, 120), material);

mesh1.position.y = 0;
mesh1.position.x = 0;

scene.add(mesh1);

const sectionMeshes = [mesh1];

/**
 * Particles
 */

// Geometry
const particlesCount = 200;
const positions = new Float32Array(particlesCount * 3);

for (let i = 0; i < particlesCount; i++) {
  positions[i * 3] = (Math.random() - 0.5) * 10;
  positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length;
  positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
}

const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

// Materital
const particlesMaterial = new THREE.PointsMaterial({
  color: parameters.materialColor,
  sizeAttenuation: true,
  size: 0.03
});

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial);
scene.add(particles);

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3);
directionalLight.position.set(1, 2, 0);
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */

// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 8;
cameraGroup.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableZoom = false;
controls.enablePan = false;
controls.enableDamping = true;
controls.dampingFactor = 0.01;
controls.autoRotate = true;
controls.autoRotateSpeed = 0.25;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Animate Meshes
  for (const mesh of sectionMeshes) {
    mesh.rotation.x += deltaTime * meshRotationX;
    mesh.rotation.y += deltaTime * meshRotationY;
  }

  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
